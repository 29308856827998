import { render, staticRenderFns } from "./ProductDetails.vue?vue&type=template&id=089c8f0a&"
import script from "./ProductDetails.vue?vue&type=script&lang=js&"
export * from "./ProductDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VBottomSheet,VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VDivider,VIcon,VList,VListItem,VListItemActionText,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VProgressLinear,VSheet,VSubheader,VTextField,VTooltip})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
