<template>
  <v-sheet>
    <div
      v-if="product"
      class="d-flex text-subtitle-2 font-weight-light mb-4 justify-center"
    >
      <div>
        {{ product.name }}
      </div>
      <v-divider class="mx-2" vertical />
      <div>
        {{ balanceSummary ? balanceSummary.quantity : 0 | formatNumber }}
        {{ product.measureWeight }}
      </div>
      <v-divider class="mx-2" vertical />
      <div>
        {{ balanceSummary ? balanceSummary.count : 0 | formatInteger }} Records
      </div>
      <div class="ml-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              class="days-icon"
              @click="chartOptionDialogOpen = true"
              >mdi-calendar-range</v-icon
            >
          </template>
          <span>Change History Days</span>
        </v-tooltip>
      </div>
    </div>

    <div v-if="pendingOrderStatus">
      <span class="text-subtitle-2 font-weight-light">
        Outstanding Orders
      </span>
      <div class="my-4" @click="viewOrderDetails">
        <v-progress-linear
          class="pointer"
          :value="
            (pendingOrderStatus.totalFilledQuantity /
              pendingOrderStatus.totalOrderQuantity) *
            100
          "
          color="primary"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
        <div class="text-caption">
          {{ pendingOrderStatus.totalFilledQuantity | formatNumber }} of
          {{ pendingOrderStatus.totalOrderQuantity | formatNumber }}
          {{ product.measureWeight }} filled from
          {{ pendingOrderStatus.customers.length }} customers
        </div>

        <v-alert
          class="ma-4"
          colored-border
          border="left"
          :type="lowInventory ? 'warning' : 'success'"
        >
          <div v-if="lowInventory" class="font-weight-light">
            <v-icon class="my-auto mx-2" color="warning"> mdi-alert </v-icon>
            {{ $vuetify.lang.t("$vuetify.dashboard.lowBalanceTitle") }}
          </div>

          {{
            (pendingOrderStatus.totalOrderQuantity -
              pendingOrderStatus.totalFilledQuantity)
              | formatNumber
          }}
          {{ product.measureWeight }} Delivery obligation /
          {{ balanceSummary ? balanceSummary.quantity : 0 | formatNumber }}
          {{ product.measureWeight }} Available
        </v-alert>
      </div>

      <v-divider class="my-2" />
    </div>

    <div v-if="balanceHistory">
      <v-sheet :elevation="2" :height="225" class="pa-3 d-flex justify-center">
        <!-- <v-select label="Range" v-model="historicalRange" :items="rangeOptions" return-object/> -->
        <line-chart
          :show-legend="hasCostAndPrice ? true : false"
          :data="balanceHistory"
          :options="lineOptions"
          :height="200"
          width="100%"
          :title="historyChartTitle"
          :responsive="true"
          :maintainAspectRatio="false"
        />
      </v-sheet>

      <v-dialog v-model="chartOptionDialogOpen" max-width="400">
        <v-card>
          <v-card-title>
            <span class="headline">Change History Days</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="historyDays"
              label="Number of Days"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="chartOptionDialogOpen = false">Cancel</v-btn>
            <v-btn color="primary" @click="updateHistoryDays">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <v-progress-linear indeterminate />
      <span class="text-caption">Loading balance history...</span>
    </div>
    <v-divider class="my-4" />
    <v-sheet :elevation="2" :height="325" class="pa-3 d-flex justify-center">
      <bar-chart
        class="mb-6"
        :show-legend="false"
        v-if="locationData"
        title="Inventory by Locations"
        :data="locationData"
        @click="handleChartClick"
        :height="300"
        width="80%"
        :responsive="true"
        :maintainAspectRatio="false"
      />
    </v-sheet>
    <div ref="inventoryList" v-if="selectedGroup">
      <v-divider class="my-6" />
      <div class="d-flex justify-space-between">
        <v-subheader>
          <span
            >Found
            {{
              selectedGroup.inventories ? selectedGroup.inventories.length : 0
            }}
            records at location {{ selectedGroup.locationName }}</span
          >
        </v-subheader>
      </div>
      <v-sheet
        @click="viewInventoryDetails(inventory)"
        v-ripple
        :elevation="2"
        class="my-2 pa-2"
        v-for="(inventory, index) in selectedGroup.inventories"
        :key="inventory.id"
      >
        <div class="d-flex text-caption justify-space-between">
          <span> {{ index + 1 }}. ID: {{ inventory.inventoryId }} </span>
          <span> {{ locationName(inventory.locationId) }} </span>
          <span> {{ inventory.stockedDate | formatDateYear }}</span>
        </div>
        <div class="text-center text-h5 ma-6">
          <div>
            <span
              v-if="inventory.quantityRemaining < inventory.originalQuantity"
            >
              {{
                (inventory.quantityRemaining - inventory.reservedQuantity)
                  | formatNumber
              }}
              /&nbsp;
            </span>
            {{ inventory.originalQuantity | formatNumber }}
            {{ product.measureWeight }}
          </div>
          <div
            v-if="product && product.children && product.children.length > 0"
            class="text-caption"
          >
            {{ inventory.product }}
          </div>
        </div>
        <div
          class="mb-4 d-flex justify-center font-weight-light"
          v-if="inventory.reserved"
        >
          {{ inventory.reservedQuantity | formatNumber }}
          {{ product.measureWeight }} Reserved
        </div>
        <div class="mb-4 d-flex justify-space-around">
          <div v-if="inventory.inputQuantityUsed">
            <span class="mr-4">Production</span>
            <span
              >{{ inventory.inputQuantityUsed | formatNumber }}
              {{ product.measureWeight }}</span
            >
          </div>
          <div v-if="inventory.adjustmentDeductionQuantity">
            <span class="mr-4">Adjustment</span>
            <span
              >{{ inventory.adjustmentDeductionQuantity | formatNumber }}
              {{ product.measureWeight }}</span
            >
          </div>
          <div v-if="inventory.quantitySold">
            <span class="mr-4">Sold</span>
            <span
              >{{ inventory.quantitySold | formatNumber }}
              {{ product.measureWeight }}</span
            >
          </div>
        </div>
        <div class="text-caption text-left">
          Doc #: {{ inventory.documentNumber }}
        </div>
      </v-sheet>
    </div>

    <v-bottom-sheet scrollable v-model="showBottomSheet">
      <v-card>
        <v-card-actions class="justify-center">
          <v-btn class="mt-6" text @click="hideBottomSheet">
            {{ $vuetify.lang.t("$vuetify.close") }}
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <inventory-details
            v-if="showInventoryDetails"
            :key="selectedInventoryId"
            v-model="selectedInventoryId"
            @updatedLocation="updateLocation"
          />

          <div v-else-if="showSalesOrders">
            <v-list>
              <v-list-item-group>
                <v-list-item
                  @click="linkOrder(order)"
                  v-for="order in pendingOrderStatus.orders"
                  :key="order.id"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ order.customerName }} ({{ order.status }})
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ order.salesOrderCode }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ order.salesDate | formatDateYear }}
                  </v-list-item-action-text>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div v-else>No details</div>
        </v-card-text>
      </v-card>
      <!-- <v-sheet class="text-center" style="max-height: 380px; overflow: scroll">
      </v-sheet> -->
    </v-bottom-sheet>
    <confirmation-dialog ref="confirm" />
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const InventoryDetails = () =>
  import("@/components/inventory/InventoryDetails.vue");
import moment from "moment";
import { tagReservedInventories } from "@/services/inventoryReservation.js";
const BarChart = () => import("@/components/charts/BarChart.vue");
const LineChart = () => import("@/components/charts/LineChart.vue");
import { fetchProductOrdersSummary } from "@/services/orderServices";

export default {
  components: { InventoryDetails, BarChart, LineChart },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    balanceSummary: null,
    balanceHistory: null,
    inventories: [],
    selectedGroup: null,
    selectedInventoryId: null,
    locationData: null,
    lineOptions: {},
    rangeOptions: ["14 Days", "4 Weeks", "3 Months"],
    pendingOrderStatus: null,
    historyDays: 90,
    showSalesOrders: false,
    showInventoryDetails: false,
    showBottomSheet: false,
    chartOptionDialogOpen: false,
  }),
  async mounted() {
    await this.loadLocations();
    this.balanceSummary = this.startBalanceService.getBalanceByProduct(
      this.product
    );
    if (this.balanceSummary && this.balanceSummary.inventories) {
      this.inventories = this.balanceSummary.inventories;
      await tagReservedInventories(this.inventories);
      this.inventories = this.inventories.sort(
        (a, b) => b.inventoryId - a.inventoryId
      );

      this.locationData = this.renderChartDataByLocation(this.inventories);
    }
    this.fetchBalanceHistory(this.product);

    this.fetchOrderStatus(this.product);
  },
  computed: {
    ...mapGetters("auth", ["currentUser", "userRoles"]),
    ...mapGetters("inventoryBalances", ["startBalanceService"]),
    ...mapGetters("locations", ["locations"]),

    historyChartTitle() {
      return `Balance History (${this.historyDays} days)`;
    },
    isAdministrator() {
      const permissibleRoles = ["Administrator"];
      if (
        this.userRoles &&
        this.userRoles.some((r) => permissibleRoles.includes(r))
      ) {
        return true;
      } else {
        return false;
      }
    },
    hasCostAndPrice() {
      // const permissibleRoles = ["Cost", "Price"];
      const permissibleRoles = ["Administrator"];
      if (
        this.userRoles &&
        this.userRoles.some((r) => permissibleRoles.includes(r))
      ) {
        return true;
      } else {
        return false;
      }
    },
    lowInventory() {
      let low = false;
      const obligation = this.pendingOrderStatus
        ? this.pendingOrderStatus.totalOrderQuantity -
          this.pendingOrderStatus.totalFilledQuantity
        : null;
      const balance = this.balanceSummary ? this.balanceSummary.quantity : 0;

      if (obligation) {
        low = obligation > balance * 1.1;
      }

      return low;
    },
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("locations", ["loadLocations"]),

    viewInventoryDetails(inventory) {
      this.selectedInventoryId = inventory.inventoryId;
      this.showInventoryDetails = true;
      this.showBottomSheet = true;
    },
    viewOrderDetails() {
      this.showBottomSheet = true;
      this.showSalesOrders = true;
    },
    hideBottomSheet() {
      this.showBottomSheet = false;
      this.showSalesOrders = false;
      this.showInventoryDetails = false;
    },
    linkOrder(order) {
      console.log(order);
      this.$router.push({
        name: "SalesOrder",
        query: { q: order.customerName },
      });
    },
    async fetchOrderStatus(product) {
      this.pendingOrderStatus = await fetchProductOrdersSummary(product.id);
    },
    fetchBalanceHistory(product) {
      const startDate = moment()
        .subtract(this.historyDays, "days")
        .format("YYYY-MM-DD");
      const endDate = moment().format("YYYY-MM-DD");
      const productIds =
        product.leaveNodeIds && product.leaveNodeIds.length > 0
          ? product.leaveNodeIds
          : [product.id];
      const params = {
        start: startDate,
        end: endDate,
        productIds: productIds.join(","),
      };
      this.$axios
        .get("/balanceSnapshot/by-products", { params })
        .then((resp) => {
          const history = resp.data;
          const date2Balance = history.reduce((groupByDate, row) => {
            const quantity = row.quantity ? row.quantity : 0;
            const cost = row.cost ? row.cost : 0;
            if (groupByDate[row.balanceDate]) {
              groupByDate[row.balanceDate].quantity += quantity;
              groupByDate[row.balanceDate].cost += cost;
            } else {
              groupByDate[row.balanceDate] = { quantity, cost };
            }
            return groupByDate;
          }, {});

          this.balanceHistory =
            this.renderChartDataByBalanceHistory(date2Balance);
        });
    },
    getMonths(numberOfMonths) {
      const months = [];

      for (let i = 1; i <= numberOfMonths; i++) {
        const m = moment()
          .startOf("month")
          .subtract(numberOfMonths - i, "M")
          .clone()
          .startOf("month");
        months.push(m.format("YYYY-MM-DDTHH:mm:ss"));
      }
      return months;
    },
    renderChartDataByBalanceHistory(balanceHistory) {
      this.lineOptions = {
        scales: {
          A: {
            type: "linear",
            position: "left",
            display: "auto",
          },
        },
      };
      const chartData = {
        labels: [],
        datasets: [
          {
            label: "Balance",
            yAxisID: "A",
            data: [],
            fill: true,
            tension: 0.3,
            borderColor: "#f87979",
          },
        ],
      };
      if (this.hasCostAndPrice) {
        chartData.datasets.push({
          label: "Unit Cost",
          yAxisID: "B",
          data: [],
          fill: true,
          tension: 0.3,
          borderColor: "#01579B",
          hidden: false,
        });

        this.lineOptions.scales["B"] = {
          type: "linear",
          position: "right",
          display: "auto",
          ticks: {
            color: "#01579B",
          },
        };
        this.lineOptions.scales["A"].ticks = {
          color: "#f87979",
        };
      }
      const dates = Object.keys(balanceHistory);
      const values = Object.values(balanceHistory);
      chartData.labels = dates.map((d) => d.substring(5, 10));
      chartData.datasets[0].data = values.map((v) => v.quantity);

      if (this.hasCostAndPrice) {
        chartData.datasets[1].data = values.map((v) =>
          parseFloat(v.cost / v.quantity, 2)
        );
      }

      return chartData;
    },
    renderChartDataByLocation(inventories) {
      const location2Inventories = inventories.reduce(
        (locationSum, inventory) => {
          const location = inventory.locationId;
          let sum = locationSum.get(location);
          if (!sum) {
            sum = {
              location,
              originalQuantity: 0,
              quantityRemaining: 0,
              count: 0,
              inventories: [],
            };
            locationSum.set(location, sum);
          }

          sum.quantityRemaining += inventory.quantityRemaining;
          sum.originalQuantity += inventory.originalQuantity;
          sum.count++;
          sum.inventories.push(inventory);

          return locationSum;
        },
        new Map()
      );

      const chartData = {
        labels: [],
        datasets: [
          {
            label: "Locations",
            backgroundColor: "#f87979",
            data: [],
            dataObject: [],
          },
        ],
      };
      location2Inventories.forEach((value, key) => {
        let label = this.locationName(key);
        chartData.labels.push(label);
        const totalWeight = value.quantityRemaining;
        chartData.datasets[0].data.push(totalWeight);
        value.locationName = label;
        chartData.datasets[0].dataObject.push(value);
      });

      return chartData;
    },
    locationName(locationId) {
      if (locationId && this.locations) {
        const location = this.locations.find((l) => l.id == locationId);
        if (location) {
          return location.name;
        }
      }
      return "No Location";
    },
    async markAsWaste() {
      let confirm = await this.$refs.confirm.open(
        "Confirm Inventory State Change",
        `Please confirm you want to mark all remaining balance (${this.$options.filters.formatNumber(
          this.balanceSummary ? this.balanceSummary.quantity : 0
        )}) of ${
          this.product.name
        } as waste.  This will make the inventory <b>unavailable</b> and can not be reversed?`
      );
      if (confirm) {
        this.$axios
          .put("/inventory/mark-as-waste", null, {
            params: { productId: this.product.id },
          })
          .then(() => {
            window.location.reload();
          })
          .catch(() => {
            this.addErrorMessage("Error marking product balance as waste");
          });
      }
    },
    async handleChartClick(item) {
      this.selectedGroup = item.data;
      await new Promise((resolve) => setTimeout(resolve, 300));
      this.scrollToList();
    },
    scrollToList() {
      const el = this.$refs.inventoryList;
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    updateLocation(updatedInventory) {
      const inventory = this.inventories.find(
        (i) => i.inventoryId == updatedInventory.id
      );
      inventory.locationId = updatedInventory.locationId
        ? updatedInventory.locationId
        : null;
      inventory.locationName = updatedInventory.locationName
        ? updatedInventory.locationName
        : null;
      this.locationData = this.renderChartDataByLocation(this.inventories);
    },
    updateHistoryDays() {
      this.chartOptionDialogOpen = false;
      this.fetchBalanceHistory(this.product);
    },
  },
};
</script>

<style></style>
